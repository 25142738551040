<template>
    <div id="app-lesson" class="app-add" style="height:calc(100% - 20px)">
       <iframe id="iframe"  style="width:100%; height:100%; border: 0px" ></iframe></div>
</template>

<script>
    import {UrlEnum} from "../../../public/js/common-vue";
    import { request } from "../../../public/js/common-vue";

    export default {
        data() {
            return {}
        },
        methods: {
          initData: function () {
                this.$http({
                    method: "get",
                    url: UrlEnum.KNOWLEDGE + "/" + request('id'),
                }).then((res) => {
                  this.getVodToken(res.data.supportVideo);
                }).catch((res) => {
                    if(res.response != null){
                        const data = res.response.data;
                        this.showMsgError(data.message);
                    }
                });
            },
           getVodToken: function (videoId){
            this.$http({
              method: "get",
              url: UrlEnum.BAIJIA_VOD_TOKEN+"?videoId=" + videoId,
            }).then((res) => {
              var videoId = res.data.videoId;
              var token = res.data.token;
              document.getElementById("iframe").src="vod.html?videoId="+videoId+"&token="+token;
            }).catch((res) => {
              if(res.response != null){
                const data = res.response.data;
                this.showMsgError(data.message);
              }
            });
          }
        },
        mounted() {
            this.initData();
        }
    }
</script>

<style scoped>
    @import "../../../static/css/add.css";
</style>